const functions =  {
    availableMethods() {
        return [
            'getBuyerOrders',
        ]
    },

    getBuyerOrders(data) {
        const url = `${this.modelName}/buyer`;

        return this._callApi(url, 'get', data)
    }
};

export default functions
