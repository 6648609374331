const functions =  {
    availableMethods() {
        return [
            'createOne',
            'updateOne',
            'getOne',
            'deleteOne',
            'getProductAll',
            'complete',
            'getBuyerAuctions',
            'getSellerAuctions',
            'getProductAuction'
        ]
    },

    getSellerAuctions(data) {
        const url = `${this.modelName}/get-seller`;

        return this._callApi(url, 'get', data)
    },

    getBuyerAuctions(data) {
        const url = `${this.modelName}/get-buyer`;

        return this._callApi(url, 'get', data)
    },

    getProductAll(data) {
        const url = `${this.modelName}/product-all`;

        return this._callApi(url, 'get', data)
    },

    complete(id, bidId) {
        const url = `${this.modelName}/${id}/complete`;

        return this._callApi(url, 'post', { bid_id: bidId })
    },

    getProductAuction(data) {
        const url = `${this.modelName}/get-product`;

        return this._callApi(url, 'get', data)
    },
};

export default functions
