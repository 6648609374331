const functions =  {
    availableMethods() {
        return [
            'getRegion',
            'getProvince',
            'getCity',
            'getBarangay'
        ]
    },

    getRegion() {
        const url = `${this.modelName}/getRegion`;

        return this._callApi(url, 'get', {})
    },

    getProvince(region) {
        const url = `${this.modelName}/getProvince`;

        return this._callApi(url, 'get', {region})
    },

    getCity(region, province) {
        const url = `${this.modelName}/getCity`;

        return this._callApi(url, 'get', {region, province})
    },

    getBarangay(region, province, city) {
        const url = `${this.modelName}/getBarangay`;

        return this._callApi(url, 'get', {region, province, city})
    }
};

export default functions
