const functions =  {
    availableMethods() {
        return [
            'getNotificationCount',
            'getShortNotifications',
            'getAllNotifications',
            'readAll',
            'readNotification',
            'newAuctionAdded',
            'newBidAdded',
            'deleteAll',
        ]
    },

    newBidAdded(data) {
        const url = `${this.modelName}/new-bid`;

        return this._callApi(url, 'post', data)
    },

    newAuctionAdded(data) {
        const url = `${this.modelName}/new-auction`;

        return this._callApi(url, 'post', data)
    },

    getNotificationCount() {
        const url = `${this.modelName}/count`;

        return this._callApi(url, 'get', {})
    },

    getShortNotifications() {
        const url = `${this.modelName}/short`;

        return this._callApi(url, 'get', {})
    },

    getAllNotifications(data) {
        const url = `${this.modelName}/all`;

        return this._callApi(url, 'get', data)
    },

    readAll() {
        const url = `${this.modelName}/read`;

        return this._callApi(url, 'post', {})
    },

    readNotification(id) {
        const url = `${this.modelName}/read-one/${id}`;

        return this._callApi(url, 'post', {})
    },

    deleteAll() {
        const url = `${this.modelName}/delete`;

        return this._callApi(url, 'post', {})
    },
};

export default functions
