const functions =  {
    availableMethods() {
        return [
            'me',
            'updateOne',
        ]
    },

    me() {
        const url = `${this.modelName}/me`
        return this._callApi(url)
    },
}

export default functions
