const functions =  {
    availableMethods() {
        return [
            'createOne',
        ]
    },

    createOne(data) {
        const url = `${this.modelName}/new`;

        return this._callApi(url, 'post', data)
    },
};

export default functions
