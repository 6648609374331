const functions =  {

    availableMethods() {
        return [
            'login',
            'logout',
            'register',
            'socialLogin',
            'check',
            'sendResetPasswordCode',
            'resetPassword',
            'passwordChange',
            'checkCode'
        ]
    },

    login(email, password, type) {
        const url = `${this.modelName}/login`

        const data = {
            email,
            password,
            role_type: type
        }

        return this._callApi(url, 'post', data)
    },

    logout() {
        const url = `${this.modelName}/logout`

        return this._callApi(url, 'post').then(
            () => {
                Eloquent.unsetApiRequestToken()
            }
        )

    },

    passwordChange(data) {
        const url = `${this.modelName}/password-change`
        return this._callApi(url, 'post', data)
    },

    register(data) {
        const url = `${this.modelName}/register`
        return this._callApi(url, 'post', data)
    },
    check(email, type) {
        const url = `${this.modelName}/check`
        const data = {
            email,
            role_type: type
        }
        return this._callApi(url, 'post', data)
    },

    // refreshToken(data) {
    //     const url = `${this.modelName}/refresh`
    //
    //     return this._callApi(url, 'post', data)
    // },

    socialLogin(provider, token) {
        const url = `${this.modelName}/oauth`

        const data = {
            provider,
            token
        }

        return this._callApi(url, 'post', data)
    },

    checkLoginStatus() {
        const url = `${this.modelName}/check`

        return this._callApi(url, 'get')
    },

    checkEmail(email, type) {
        const url = `${this.modelName}/email/check`

        const data = {
            email,
            role_type: type
        }

        return this._callApi(url, 'post', data)
    },

    sendResetPasswordCode(email){
        const url = `password/forgot`

        const data = {
            email
        }

        return this._callApi(url, 'post', data)
    },

    resetPassword(token, password){
        const url = `password/reset`

        const data = {
            token,
            password
        }
        return this._callApi(url, 'post', data)
    },

    checkCode(code){
        const url = `password/reset/check`

        const data = {
            code
        }
        return this._callApi(url, 'post', data)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('This model do not have attributes')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('This model do not have attributes')
        })
    }
}

export default functions
