const functions =  {
    availableMethods() {
        return [
            'createOne',
            'updateOne',
            'getOne',
            'deleteOne',
            'getAllAuctionBids',
        ]
    },

    getAllAuctionBids(data) {
        const url = `${this.modelName}/get-auction`;

        return this._callApi(url, 'get', data)
    },
};

export default functions
