const functions =  {
    availableMethods() {
        return [
            'editProduct',
            'getTrending',
            'getProduct',
            'getSimilar',
            'search',
            'getBrands',
            'deleteOne',
            'dummy',
            'searchTags',
            'searchColors',
        ]
    },

    searchTags(search) {
        const url = `${this.modelName}/tags`;

        return this._callApi(url, 'get', {
            search
        })
    },

    searchColors(search) {
        const url = `${this.modelName}/colors`;

        return this._callApi(url, 'get', {
            search
        })
    },

    deleteOne($id) {
        const url = `${this.modelName}/delete/${$id}`;

        return this._callApi(url, 'post', {})
    },

    getProduct($id) {
        const url = `${this.modelName}/${$id}`;

        return this._callApi(url, 'get', {})
    },

    dummy() {
        const url = `${this.modelName}/dummy`;

        return this._callApi(url, 'get', {})
    },

    getTrending(data) {
        const url = `${this.modelName}/trending`;

        return this._callApi(url, 'get', data)
    },

    getBrands(data) {
        const url = `${this.modelName}/brands`;

        return this._callApi(url, 'get', data)
    },

    getSimilar(data) {
        const url = `${this.modelName}/similar`;

        return this._callApi(url, 'post', data)
    },

    editProduct(data) {
        const url = `${this.modelName}/edit-product`;

        return this._callApi(url, 'post', data)
    },

    search(data) {
        const url = `${this.modelName}/search`;

        return this._callApi(url, 'post', data)
    },
};

export default functions
