const functions =  {
    availableMethods() {
        return [
            'createOne',
            'deleteOne',
            'getProductInventory',
            'getMyInventory',
            'deleteByProduct',
        ]
    },

    getProductInventory(data) {
        const url = `${this.modelName}/product`;

        return this._callApi(url, 'get', data)
    },

    getMyInventory(data) {
        const url = `${this.modelName}/get-all`;

        return this._callApi(url, 'get', data)
    },

    deleteByProduct(data) {
        const url = `${this.modelName}/delete-product`;

        return this._callApi(url, 'post', data)
    },
};

export default functions
