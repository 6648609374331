window._ = require('lodash')
window._axios = require('axios')
window.Eloquent = require('./Eloquent').default

let settings = {
    //todo change urls
    baseDomain: process.env.NODE_ENV === 'production' ? 'https://api.tender.sale/' : (process.env.NODE_ENV === 'development' ? 'https://tnqogexoqh.api.tender.sale/' : 'http://localhost:8000/')
}

if (process.env.NODE_ENV !== 'production') {
    if (window._playground) {
        settings = {
            baseDomain: ''
        }
    }
}

_axios = _axios.create({
    baseURL: settings.baseDomain,
    withCredentials: true
})

_axios.defaults.validateStatus = status => {
    return status >= 200 && status < 500
}

_axios.interceptors.response.use(response => {
    response.isOk = response.status >= 200 && response.status < 300
    return response
})

const models = {
    Auth: require('./Auth').default,
    User: require('./User').default,
    Registered: require('./Registered').default,
    Category: require('./Category').default,
    Suggestion: require('./Suggestion').default,
    Product: require('./Product').default,
    Inventory: require('./Inventory').default,
    Bid: require('./Bid').default,
    Notifications: require('./Notifications').default,
    Auction: require('./Auction').default,
    Order: require('./Order').default,
    Address: require('./Address').default
}

_.each(models, (modelFunctions, modelName) => {
    Eloquent[modelName] = Eloquent._makeClass(modelName, modelFunctions)
})
